<template>
  <div>
    <portal to="more">
      <v-subheader>Downloads</v-subheader>
      <ReportItem
        v-if="term"
        text
        :parameters="{
          term: term.id,
          divisions: filterDivision[0] == 0 ? [] : filterDivision,
          time: filter,
        }"
        resource="report/events"
        >Terminliste (pdf)</ReportItem
      >
    </portal>
    <v-toolbar flat>
      <v-row class="align-center justify-center">
        <v-switch
          inset
          hideDetails
          v-model="filterChanged"
          label="nur geänderte"
          class="mr-2"
        ></v-switch>
        <v-switch
          :disabled="!term || !term.current"
          inset
          hideDetails
          v-model="filterFuture"
          label="nur zukünftige"
        ></v-switch>
        <v-spacer></v-spacer>
        <TermPicker text outlined v-model="term" :range="[-2, 1]" />
        <v-spacer />
        <DivisionFilter v-model="filterDivision" />
      </v-row>
    </v-toolbar>

    <v-card
      :loading="loading"
      v-for="(group, key) in itemsGrouped"
      :key="'bar' + key"
      class="mb-4"
    >
      <v-system-bar
        :color="key == formatDate(today, true, true) ? 'primary' : ''"
        :dark="key == formatDate(today, true, true)"
      >
        {{ key }}
        {{
          key == formatDate(today, true, true) ? "(heute)" : ""
        }}</v-system-bar
      >
      <v-list :key="'list' + key" dense class="py-0" expand>
        <EventItem v-for="item in group" :key="item.id" :value="item" />
      </v-list>
    </v-card>

    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DivisionFilter from "@/components/DivisionFilter";
import EventItem from "common/components/EventItem.vue";
import ReportItem from "@/components/ReportItem";
import TermPicker from "common/components/TermPicker";

import { groupBy } from "lodash";
import {
  formatDatespan,
  formatDate,
  today,
  isCurrent,
  isFuture,
  isPast,
} from "common/utils/date.js";
import { copyToClipboard } from "common/utils/helper.js";

export default defineComponent({
  name: "EventList",
  components: {
    DivisionFilter,
    EventItem,
    ReportItem,
    TermPicker,
  },
  data() {
    return {
      currentTerm: null,
      loading: false,
      search: "",
      divisions: [],
      filter: 1,
      filterDivision: [0],
      filterChanged: false,
      filterFuture: true,
      items: [],
      term: null,
    };
  },
  computed: {
    isTeacher() {
      return this.$_hasRole("teacher");
    },
    itemsGrouped() {
      return groupBy(this.itemsFiltered, (el) =>
        formatDatespan(el.startDate, el.endDate, true, true)
      );
    },
    itemsMapped() {
      return this.items.map((el) => {
        return {
          ...el,
          changed: this.isNew(el),
          current: this.isCurrent(el),
          past: this.isPast(el),
          future: this.isFuture(el),
        };
      });
    },
    today,
    itemsFiltered() {
      return this.itemsMapped.filter(
        (item) =>
          (!this.filterFuture || (this.filterFuture && item.future)) &&
          (!this.filterChanged || (this.filterChanged && item.changed)) &&
          (item.division == null ||
            this.filterDivision.includes(0) ||
            this.filterDivision.includes(item.division.id))
      );
    },
  },
  watch: {
    term() {
      if (!this.ternm || !this.term.current) {
        this.filterFuture = false;
      }
      this.fetchEvents();
    },
  },
  methods: {
    formatDate,
    copyToClipboard,
    changed() {
      const oldQueryString = this.queryString;
      this.queryString = "groupType=" + this.active.id;
      if (this.queryString !== oldQueryString) {
        this.getData();
        localStorage.setItem("groupType", this.queryString);
      }
    },
    getUrl(division) {
      if (!division) {
        return `${this.backendServer()}api/ical/event${
          this.isTeacher ? "?teacher" : ""
        }`;
      }
      return `${this.backendServer()}api/ical/event?division=${division.id}${
        this.isTeacher ? "&teacher" : ""
      }`;
    },
    isCurrent,
    isFuture,
    isPast,
    isNew(item) {
      const deadline = new Date(this.term.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },

    async fetchData() {
      this.divisions = await this.apiList({ resource: "common/division" });
    },
    async fetchEvents() {
      if (this.term == null) {
        return;
      }
      this.loading = true;
      this.items = await this.apiList({
        resource: "course/event",
        query: `startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      this.loading = false;
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] === "dateTime") {
        items.sort((a, b) => {
          if (!sortDesc[0]) {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? -1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? 1
              : 0;
          } else {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? 1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? -1
              : 0;
          }
        });
      }
      return items;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
